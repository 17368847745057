import React, { useMemo } from "react";
import ParticipantView from "./ParticipantView";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import { useState } from "react";
import { useEffect } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import ReactPlayer from "react-player";

export default function MeetingView(props) {
  const {
    isIncomingCall,
    setIsIncomingCall,
    callUser,
    meetingId,
    setMeetingId,
    handleStopAudio,
    endedCallTune,
    startCallTune,
  } = useClassRoom();
  const { userInfo, socket, startedMeeting } = useContentSetting();
  const { setBoxType, boxType } = props;
  const [joined, setJoined] = useState(null);
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  //Get the method which will be used to join the meeting.
  //We will also get the participants list to display all participants

  const handle_update_media = (status_type, value) => {
    socket.emit("changed_media", {
      from: userInfo._id,
      meeting_id: meetingId,
      status_type: status_type,
      value: value,
    });
  };

  const { webcamStream, webcamOn } = useParticipant(userInfo._id, {
    onMediaStatusChanged: (data) => {
      if (data.kind == "audio") {
        setMicOn(data.newStatus);
        handle_update_media("muted", !data.newStatus);
      } else if (data.kind == "video") {
        setCameraOn(data.newStatus);
        handle_update_media("webcam", data.newStatus);
      }
    },
  });

  const {
    join,
    participants,
    muteMic,
    toggleMic,
    toggleWebcam,
    end,
    enableWebcam,
  } = useMeeting({
    //callback for when meeting is joined successfully
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingEnded: () => {
      setMeetingId();
    },
  });

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  const acceptCall = () => {
    setIsIncomingCall(false);
    setJoined("JOINING");
    join();
    socket.emit("call:accepted", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
  };

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  const handletoggleMic = () => {
    toggleMic();
  };

  const handletoggleCam = () => {
    toggleWebcam();
  };

  const handleEndCall = () => {
    socket.emit("call:ended", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
  };

  const handleDeclineCall = () => {
    socket.emit("call:declined", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
    handleCallEnded();
  };

  const handleCancelCall = () => {
    socket.emit("call:canceled", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
    endedCallTune.play();
    handleStopAudio();
    end();
  };

  const handleCallEnded = () => {
    endedCallTune.play();
    handleStopAudio();
    setMeetingId(null);
    end();
  };

  useEffect(() => {
    if (startedMeeting) {
      setIsIncomingCall(false);
      setJoined("JOINING");
      setTimeout(() => {
        join();
      }, 100);
    }
    socket.on("call:ended", handleCallEnded);
    socket.on("call:no_answered", handleCallEnded);
  }, []);

  useEffect(() => {
    if (joined && startedMeeting) {
      setTimeout(() => {
        let find_participant = startedMeeting.participants.find(
          (participant) => participant.member == userInfo._id
        );
        if (find_participant) {
          if (find_participant.muted) {
            muteMic();
          }
          if (find_participant.webcam) {
            enableWebcam();
          }
        }
      }, 500);
    }
  }, [joined]);

  let find_student = [...participants.keys()].find(
    (participantId) => participantId !== userInfo._id
  );

  return (
    <>
      {joined && joined == "JOINED" ? (
        <>
          {find_student ? (
            <>
              <ParticipantView
                participantId={find_student}
                key={find_student}
                setBoxType={setBoxType}
                boxType={boxType}
              />
              {videoStream && (
                <div className="my-video-box">
                  <ReactPlayer
                    playsinline // very very imp prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    url={videoStream}
                    height={"70px"}
                    width={"110px"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="image-box">
              <img src={logo} alt="" />
            </div>
          )}
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && (
        <div className="icon-box">
          {isIncomingCall ? (
            <>
              <Tooltip title={`Join Class`}>
                <div className="success-call-box" onClick={() => acceptCall()}>
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`carbon:phone-incoming`}
                  />
                </div>
              </Tooltip>
              <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
                <div className="success-call-box" onClick={handletoggleAudio}>
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${
                      isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
                <div
                  className="success-call-box"
                  onClick={() => handletoggleMic()}
                >
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={`${cameraOn ? "Turn Off Camera" : "Turn On Camera"}`}
              >
                <div
                  className="success-call-box"
                  onClick={() => handletoggleCam()}
                >
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${
                      cameraOn ? "ic:round-camera-alt" : "mdi:camera-off"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          )}

          <Tooltip
            title={`${
              isIncomingCall
                ? "Decline Call"
                : joined == "JOINED"
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={() => {
              if (isIncomingCall) {
                handleDeclineCall();
              } else if (joined == "JOINED") {
                handleEndCall();
              } else {
                handleCancelCall();
              }
            }}
          >
            <div className="success-call-box error">
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
