import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import AddOrUpdateFees from "../../components/FeesManagement/AddOrUpdateFees";
import {
  delete_fees_request_api,
  fees_management_list_api,
} from "../../DAL/FeesManagement/FeesManagement";
import { baseUrl } from "../../config/config";
import { currency_sign, show_proper_words } from "../../utils/constant";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  {
    id: "currency",
    label: "Total Fee",
    renderData: (row) => {
      const total_amount = row.fees_info.reduce(
        (total, entry) => total + entry.amount,
        0
      );
      return <div>{currency_sign(row.currency) + total_amount}</div>;
    },
  },
  {
    id: "fees_info",
    label: "Fees Info",
    renderData: (row) => {
      return (
        <ul className="fees-info-list">
          {row.fees_info.length > 0 &&
            row.fees_info.map((item) => {
              let { month, amount, year } = item;
              return (
                <li className="mb-1">
                  {`${show_proper_words(month)} ${year} --- ${
                    currency_sign(row.currency) + amount
                  }`}
                </li>
              );
            })}
        </ul>
      );
    },
  },
  {
    id: "fee_status",
    label: "Fee Status",
    renderData: (row) => {
      let fee_status = row.fee_status;
      let class_name = "";
      if (fee_status === "pending") {
        class_name = "bg-danger";
      } else if (fee_status === "submitted") {
        class_name = "bg-primary";
      }
      return (
        <span className={`fee-status ${class_name}`}>
          {show_proper_words(fee_status)}
        </span>
      );
    },
  },
  {
    id: "table_avatar",
    label: "Receipt Slip",
    type: "thumbnail",
    show_online: true,
    className: "reduce-cell-padding",
  },
  { id: "action", label: "Action", type: "action" },
];

export default function FeesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [feesList, setFeesList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setFormType("ADD");
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [];
    if (row.fee_status !== "received") {
      MENU_OPTIONS.push({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
    }
    return MENU_OPTIONS;
  };

  const get_fees_list = async () => {
    const result = await fees_management_list_api();
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let student_name = request.student.name;
        let table_avatar = {
          src: baseUrl + request.receipt_slip,
          alt: "Receipt slip",
        };
        return { ...request, table_avatar, student_name };
      });
      setFeesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_fees_request_api(selectedObject._id);
    if (result.code === 200) {
      const data = feesList.filter((fee) => fee._id !== selectedObject._id);
      setFeesList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Fees Management</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Fee Request</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={feesList}
                handle_menus={handleMenu}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "EDIT" ? "Update" : "Add"} Fee Request`}
        componentToPassDown={
          <AddOrUpdateFees
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            setFeesList={setFeesList}
            selectedObject={selectedObject}
          />
        }
      />
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this fee request?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
