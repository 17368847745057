import React, { useEffect, useState } from "react";
import { useContentSetting } from "../Hooks/ContentSetting";
import peer from "../service/peer";
import ReactPlayer from "react-player";

export default function NewCallPage() {
  const { userInfo, conversation, socket } = useContentSetting();
  const [remoteSocketId, setRemoteSocketId] = useState(null);
  const [rOffer, setROffer] = useState(null);
  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [incoming, setIncoming] = useState(false);

  const handleIncommingCall = async ({ from, offer }) => {
    console.log(from, "fromfromfromfrom");
    setRemoteSocketId(from);
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    setMyStream(stream);
    console.log(`Incoming Call`, from, offer);
    setROffer(offer);
    setIncoming(true);
  };

  const sendStreams = () => {
    for (const track of myStream.getTracks()) {
      peer.peer.addTrack(track, myStream);
    }
  };

  const handleAcceptCall = async () => {
    const ans = await peer.getAnswer(rOffer);
    console.log(remoteSocketId, "remoteSocketId handleAcceptCall");
    socket.emit("call:accepted", {
      to: remoteSocketId,
      from: userInfo._id,
      ans,
    });
  };

  const handleNegoNeedIncomming = async ({ from, offer }) => {
    console.log(offer, "handleNegoNeedIncomming A");
    const ans = await peer.getAnswer(offer);
    socket.emit("peer:nego:done", { to: remoteSocketId, ans });
  };

  const handleNegoNeedFinal = async ({ ans }) => {
    console.log("handleNegoNeedFinal A");
    await peer.setLocalDescription(ans);
  };

  const handleNegoNeeded = async () => {
    console.log("handleNegoNeeded S");
    const offer = await peer.getOffer();
    socket.emit("peer:nego:needed", {
      offer,
      from: userInfo._id,
      to: remoteSocketId,
    });
  };

  const handleIceIncomming = async ({ from, offer }) => {
    console.log("handleNegoNeedIncomming A");
    const ans = await peer.getAnswer(offer);
    socket.emit("ice:gather:done", { to: remoteSocketId, ans });
  };

  const handleIceFinal = async ({ ans }) => {
    console.log("handleNegoNeedFinal A");
    await peer.setLocalDescription(ans);
  };

  const handleIceNeeded = async () => {
    const offer = await peer.getOffer();
    console.log(offer, "handleIceNeeded S");
    socket.emit("ice:gather:needed", {
      offer,
      from: userInfo._id,
      to: "651446e8b73bca990adde64a",
    });
  };

  useEffect(() => {
    peer.peer.addEventListener("track", async (ev) => {
      const remoteStream = ev.streams;
      console.log("GOT TRACKS!!");
      setRemoteStream(remoteStream[0]);
    });
  }, []);

  useEffect(() => {
    peer.peer.addEventListener("icegatheringstatechange", handleIceNeeded);
    return () => {
      peer.peer.removeEventListener("icegatheringstatechange", handleIceNeeded);
    };
  }, []);

  useEffect(() => {
    peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
    };
  }, []);

  useEffect(() => {
    socket.on("incomming:offer", handleIncommingCall);
    socket.on("peer:nego:needed", handleNegoNeedIncomming);
    socket.on("peer:nego:final", handleNegoNeedFinal);
    socket.on("ice:gather:needed", handleIceIncomming);
    socket.on("ice:gather:final", handleIceFinal);

    return () => {
      socket.off("incomming:offer", handleIncommingCall);
      socket.off("peer:nego:needed", handleNegoNeedIncomming);
      socket.off("peer:nego:final", handleNegoNeedFinal);
      socket.off("ice:gather:needed", handleIceIncomming);
      socket.off("ice:gather:final", handleIceFinal);
    };
  }, [socket, remoteSocketId]);

  return (
    <div>
      {myStream && (
        <>
          <h1>My Stream</h1>
          <ReactPlayer
            playing
            // muted
            height="100px"
            width="200px"
            url={myStream}
          />
          <button onClick={sendStreams}>send Streams</button>
        </>
      )}
      {remoteStream && (
        <>
          <h1>Remote Stream</h1>
          <ReactPlayer
            playing
            // muted
            height="100px"
            width="200px"
            url={remoteStream}
          />
        </>
      )}
      {incoming && <button onClick={handleAcceptCall}>Accept</button>}
    </div>
  );
}
