import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import { useContentSetting } from "../../Hooks/ContentSetting";
import AddOrUpdateToDoList from "../../components/ToDoList/AddOrUpdateToDoList";
import {
  delete_todo_list_api,
  todo_lists_api,
} from "../../DAL/ToDoList/ToDoList";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";

export default function ToDoLists() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [toDoLists, setToDoLists] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const { userInfo } = useContentSetting();

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_todo_lists = async () => {
    const result = await todo_lists_api(userInfo.role);
    if (result.code === 200) {
      setToDoLists(result.todo_lists);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    {
      id: "priority",
      label: "Priority",
      renderData: (row) => {
        return (
          <span className={`todo-priority ${row.priority}`}>
            {row.priority}
          </span>
        );
      },
    },
    { id: "action", label: "Action", type: "action" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_todo_list_api(selectedObject._id);
    if (result.code === 200) {
      const data = toDoLists.filter(
        (todo_list) => todo_list._id !== selectedObject._id
      );
      setToDoLists(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_todo_lists();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>To Do Lists</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add To Do List</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={toDoLists}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "EDIT" ? "Update" : "Add"} To Do List`}
        componentToPassDown={
          <AddOrUpdateToDoList
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            setToDoLists={setToDoLists}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this to do list?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
