import { useParticipant } from "@videosdk.live/react-sdk";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";
import { logo } from "../../../assets";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import moment from "moment";
import { formatTime } from "../../../utils/constant";

export default function ParticipantView(props) {
  const micRef = useRef(null);
  const { setBoxType, boxType } = props;
  const {
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    screenShareStream,
    screenShareOn,
  } = useParticipant(props.participantId);

  let total_time = 0;
  const { startedMeeting } = useContentSetting();
  if (startedMeeting) {
    const startTime = moment(startedMeeting.call_started_time);
    const endTime = moment(new Date());
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  const mediaStream = useMemo(() => {
    if (screenShareOn && screenShareStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(screenShareStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn]);

  const handleCloseBox = () => {
    setBoxType("CIRCLE");
  };

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="meeting-wrapper">
      {boxType == "CIRCLE" ? (
        <div className="change-call-view">
          <Tooltip title={`Open Call Tools`}>
            <div onClick={handleCloseBox}>
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon={`ic:sharp-open-with`}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className="meeting-top-section">
          <div className="change-call-view">
            <Tooltip title={`Close Box`}>
              <div onClick={handleCloseBox}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`ic:baseline-close`}
                />
              </div>
            </Tooltip>
          </div>
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
          <div className="change-call-view">
            <Tooltip title={`${boxType == "BOX" ? "Full View" : "Box View"}`}>
              <div
                onClick={() => setBoxType(boxType == "BOX" ? "FULL" : "BOX")}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    boxType == "BOX"
                      ? "majesticons:open"
                      : "mdi:arrow-collapse-all"
                  }`}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      <audio ref={micRef} autoPlay playsInline muted={!micOn} />
      {webcamOn ? (
        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          //
          url={videoStream}
          //
          height={"135px"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      ) : screenShareOn ? (
        <ReactPlayer
          //
          playsinline // very very imp prop
          playIcon={<></>}
          //
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          //
          url={mediaStream} // passing mediastream here
          //
          height={"100%"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "presenter video error");
          }}
        />
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
    </div>
  );
}
