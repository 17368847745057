import { CircularProgress, FormControl, Select, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_student_report_api,
  student_all_reports_api,
} from "../../DAL/StudentReports/StudentReports";
import AddOrUpdateReport from "../../components/StudentReports/AddOrUpdateReport";
import moment from "moment";

export default function StudentReports() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [reportsList, setReportsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState({
    lesson_book: "all",
  });

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_reports_list = async () => {
    setIsLoading(true);
    const result = await student_all_reports_api(page, rowsPerPage, filterData);
    if (result.code === 200) {
      const data = result.reports_list.map((report) => {
        let report_date = moment(report.report_date_time).format(
          "DD-MM-YYYY hh:mm A"
        );
        return {
          ...report,
          student_name: report.student?.name,
          action_info_name: report.action_info?.name,
          quran_part_mumber: report.part_mumber ? report.part_mumber : "",
          quran_verse_number: report.verse_number ? report.verse_number : "",
          report_date: report_date,
          lesson_book_name: `${
            report.lesson_book == "quran"
              ? "Holy Quran"
              : report.lesson_book == "madani_quaida"
              ? "Madani Quadia"
              : "Noorani Quadia"
          } `,
        };
      });
      setReportsList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    get_reports_list();
  };

  const handleOpenLesson = (row) => {
    let url = "/lesson-books/";
    if (row.lesson_book == "quaida") {
      url = `${url}quida-english/page-index-${row.page_number}`;
    } else {
      url = `${url}quran-pak/part-index-${row.part_mumber}/page-index-${row.page_number}`;
    }
    window.open(url);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "report_date", label: "Report Date" },
    {
      label: "Open Lesson",
      renderData: (row) => {
        if (row.lesson_book !== "madani_quaida") {
          return (
            <span
              onClick={() => {
                handleOpenLesson(row);
              }}
            >
              Open Lesson
            </span>
          );
        }
      },
      className: "make-link",
    },
    { id: "lesson_book_name", label: "Lesson Book" },
    {
      id: "quran_part_mumber",
      label: "Part Number",
      className: "text-center",
    },
    {
      id: "page_number",
      label: "Page Number",
      className: "text-center",
    },
    {
      id: "line_number",
      label: "Line Number",
      className: "text-center",
    },
    {
      id: "quran_verse_number",
      label: "Verse Number",
      className: "text-center",
    },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleChange = (name, value) => {
    setFilterData((old) => {
      return { ...old, [name]: value };
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_student_report_api(selectedObject._id);
    if (result.code === 200) {
      const data = reportsList.filter(
        (report) => report._id !== selectedObject._id
      );
      setReportsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_reports_list();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row theme-card">
          <div className="col-12 light-border-bottom">
            <div className="p-0 p-md-3 pb-0 pt-3 row">
              <div className="col-12 col-md-8 mb-3 mb-md-0">
                <div className="mb-2 mui-table-heading text-center text-md-start">
                  <h2>All Reports List</h2>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center text-md-end">
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Report</button>
                </div>
              </div>
            </div>
            <div className="p-0 p-md-3 pt-3 row row row input-form filter-button-on-report">
              <div className="col-8 col-lg-3 mb-3 mb-md-0">
                <div className="d-flex justify-content-between">
                  <FormControl fullWidth size="small" className="form-control">
                    <Select
                      value={filterData.lesson_book}
                      label=""
                      onChange={(e) => {
                        handleChange("lesson_book", e.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="quran">Holy Quran</MenuItem>
                      <MenuItem value="quaida">Noorani Quaida</MenuItem>
                      <MenuItem value="madani_quaida">Madani Quaida</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4 col-lg-9 text-center text-md-end">
                <div className="add--button mb-3">
                  <button onClick={searchFunction}>Filter</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={reportsList}
              MENU_OPTIONS={MENU_OPTIONS}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Report"
        componentToPassDown={
          <AddOrUpdateReport
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            reportsList={reportsList}
            setReportsList={setReportsList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this report?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
