import React, { useContext, useState } from "react";
import {
  _get_associate_user_from_localStorage,
  _get_user_from_localStorage,
} from "../localStorage/localStorage";
import io from "socket.io-client";
import { baseUrl } from "../config/config";

const CreateContentSetting = React.createContext();
const _get_user_info = _get_user_from_localStorage();
const _get_associate_user = _get_associate_user_from_localStorage();

//----------------------

let socket = {};
// socket-initialization
socket = io(baseUrl + `?user_id=${_get_user_info?._id}&user_type=student`);

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [startedMeeting, setStartedMeeting] = useState({});
  const [associateUser, setAssociateUser] = useState(_get_associate_user);
  const [conversation, setConversation] = useState("");
  const [studentsGroup, setStudentsGroup] = useState([]);

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(baseUrl + `?user_id=${user_id}&user_type=student`);
  };

  const STOP_SOCKET_FORCEFULLY = () => {
    socket.disconnect();
  };

  const collection = {
    userInfo,
    studentsGroup,
    conversation,
    socket,
    unreadMessageCount,
    startedMeeting,
    associateUser,
    setStartedMeeting,
    setUnreadMessageCount,
    setUserInfo,
    setStudentsGroup,
    setConversation,
    setAssociateUser,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
